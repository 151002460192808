* {
  /* border: 1px solid red; */
}

.mainProjectsBackground {

  background: #0E0E0E;

  /* height: 100%; */

  min-height: 100vh;

  display: block;
  overflow: auto;




  /* overflow: auto; */

  scroll-snap-align: start;
}

.mainProjectsFocus {

  height: 100%;


  display: flex;
  flex-direction: column;

  /* justify-content: space-evenly; */
  align-items: center;
  justify-content: center;

  position: relative;

  padding-top: 16vh;
  /* margin-left: 100px; */
  /* margin-right: 100px; */


}

.mainProjectsFocusTitle {
  /* padding: 10px; */
  /* margin-left: 8%; */
  /* margin-top: 3%; */
  text-align: left;

  position: absolute;
  left: 7vh;
  top: 7vh;


}

.mainProjectsFocusGrid {
  /* width: 80vw;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  
  align-items: center;  
  gap: 30px;
  
  justify-content: center; */


  /* Grid */

  width: 80vw;

  display: grid;
  grid-template-columns: minmax(350px, 1fr) minmax(350px, 1fr);

  /* gap: 30px; */
  gap: 50px;

  /* margin-left: 100px; */
  /* margin-right: 100px; */

}

.mainProjectsItem {

  /* border-radius: 1%; */
  /* border-radius: 3%; */
  border-radius: 12px;



  border: white 2px solid;

  height: 500px;
  /* min-width: 350px; */
  /* width: 400px; */
  /* min-width: 70%; */
  /* width: 38vw; */

  background: grey;
  position: relative;

}

/* Grid stuff for mobile */
@media only screen and (max-width: 900px) {
  .mainProjectsFocusGrid {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 0.9fr;
    justify-content: center;
  }

}



.mainProjectsImage1 {

  /* border-radius: 2.5%; */
  border-radius: 10px;

  display: block;
  width: auto;
  height: 100%;


  /* background: url("../../content/monkey.jpeg"); */
  /* background: url('./monkey.jpeg') no-repeat; */
  background-size: cover;
  background-position: center;
  background: cover;

  transition: 0.5s ease;
}



.mainProjectsOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;

  /* optional opacity */
  /* opacity: 0.9; */
  opacity: 1;

  /* border-radius: 0 0 5% 5%; */

  /* border-radius: 4px; */
  border-radius: 0 0 10px 10px;

  overflow: hidden;
  width: 100%;
  height: 100px;
  transition: .5s ease;


  text-align: left;
}

.mainProjectsItem:hover .mainProjectsOverlay {
  /* overlay height of window */
  opacity: 0.97;
  height: 60%;
}

.mainProjectsItem:hover .mainProjectsImage1 {
  /* filter: blur(1px); */
  transition: 0.5s ease;

  /* background-color: rgb(93, 115, 49); */
  /* background-blend-mode: overlay; */

  filter: opacity(0.5) drop-shadow(0 0 0 rgb(88, 138, 78)) saturate(200%);
  
}

.mainProjectsImage1:before {
  /* background-color: inherit; */
}




/* Text stuff */
.mainProjectsBigBlockTextOrgName {
  font-size: 1.2rem;
  margin: 0;
  margin-left: 3%;
  margin-top: 3%;
  margin-bottom: 2%;

  color: yellowgreen;
}


/* chevrons */
.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: '';
  display: inline-block;
  /* display: block; */
  height: 0.55em;
  /* left: 16.15em; */

  /* text-align: right; */
  float: right;
  right: 3vw;

  /* left: 20vw; */
  position: relative;
  top: 0.55em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.55em;

  transition: 0.4s;
}

.mainProjectsItem:hover .chevron::before {
  color: black;
  transform: rotate(135deg);
  transition: 0.4s;
}



.mainProjectsBigBlockTextProjName {
  font-size: 1.4rem;
  margin: 0;
  margin-left: 3%;
}

.mainProjectsBigBlockTextProjDecrib {
  font-size: 1.1rem;
  /* font-size: 0; */

  margin: 5% 3% 3% 3%;
}

/* to prevent text from being visible on weird phones */
.mainProjectsItem:hover .mainProjectsBigBlockTextProjDecrib {
  /* font-size: 1.1rem; */
}

.mainProjectsBigBlockTextTechBubblesSection {

  margin-top: 1%;
  margin-left: 3%;
  /* margin-bottom: 20%; */

  display: flex;
  flex-direction: row;
}

.mainProjectsBigBlockTextTechBubble {}

.techBubble {
  /* color: white; */
  /* background-color: black; */

  color: black;
  /* background-color: black; */

  padding: 6px 30px 6px 0px;

  height: 20px;
  /* border-radius: 300px; */
  /* border: black solid 1px; */
  /* border-radius: 12px; */

  margin: auto;
  margin-right: 6px;
}



@media only screen and (max-width: 600px) {

  .mainProjectsBigBlockTextProjDecrib {
    margin: 10% auto 4% 3%;
  }

  .mainProjectsItem:hover .mainProjectsBigBlockTextProjDecrib {
    transition: 0.5s;
    margin: 5% auto 4% 3%;
  }

}