* {
    /* border: 1px red solid; */
}

.workBackground {

    background: yellowgreen;
    
    height: 100vh;
    
    scroll-snap-align: start;


}

.workSectionTitle {
    /* width: auto; */

    position: absolute;
    left: 7vh;
    top: 7vh;

    /* margin-left: 8%; */
    /* margin-top: 3%; */
    text-align: left;
}
.workSectionTitle .sectionNumber {
    color: black;
}
.workSectionTitle .sectionTitle {
    color: black;
}

.workSectionJobTitle {
    /* padding-top: 5px; */
    font-size: 1.4rem;
}
.workSectionJobTime {
    font-size: 1.2rem;
    padding-top: 10px;
}

.workSectionJobBlock {
    display: flex !important;
    flex-direction: column-reverse !important;

    text-align: left;
    /* margin-bottom: 50px; */

    /* background: yellow; */
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    /* justify-items: flex-start; */

    overflow: hidden;

}

.workSectionJobBlock ul {
    list-style-type: "▹ ";
    /* list-style: none; */
    text-align: left;
    padding: 0;
    
    margin-left: 10px;
}
.workSectionJobBlock li {
    font-size: 1.15rem;
    margin-top: 2.5vh;
    /* color: white; */

    /* border: solid red 1px; */

}

.workSectionJobBlock li::before {
    /* content: "▹    "; */
    color: black;
    position: relative;

}

.workSectionJobTitle {
    background: black;
    color:yellowgreen;

    padding:6px;
    display: inline;
    /* text-align: left; */
    /* align-self: flex-start; */
}


.workContent {
    color: black;

    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */

    /* align-items: center; */
    justify-content: center;
    /* justify-items: center; */


    height: 100%;

    position: relative;
}

.workSelector {
    /* background: white; */

    align-self: center;
    width: 80%;
    height: 400px;

    margin-top: 30px;
}

@media only screen and (max-width: 600px) {
    
    .workSectionJobTitle {
        /* padding-top: 5px; */
        font-size: 1.2rem;
    }
    .workSectionJobTime {
        font-size: 1.0rem;
        padding-top: 10px;
    }
    .workSectionJobBlock ul {
        padding: 0;
    }
    .workSectionJobBlock li {

        /* might need adjustment */
        font-size: 3.8vw;

    }
    .workSelector {
        /* background: white; */
    
        align-self: center;
        width: 95%;
        height: 80%;
        /* margin: auto; */
    }
    
}
