.navAreaMail {
    /* background: black; */
    background: #0E0E0E;

    width: 6%;
    /* height: 100vh; */

    /* min-height: 100vh; */
    /* min-height: -webkit-fill-available; */

    max-height: 100vh;

    /* border: red 1px solid; */
    
    /* Align to middle of sidebar */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mail {
    /* border: red 1px solid; */
    

    /* display: flex;
    flex-direction: column;
    align-items: center; */
    
    /* position: sticky;
    top: 75%; */

    position: fixed;
    top: 60%;

}

.snackbar {
    z-index: 3;
}

.linkMail {
    color: white;
    writing-mode: vertical-lr;
    
    transition: 0.3s;
    letter-spacing: 0.3em;
}
.linkMail:hover {
    color: greenyellow;
    transform: translateY(-7px);
    transition: 0.3s;
    cursor: pointer;
}

.mail::after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 110px;
    margin: 20px auto 0px auto;
    background-color: white;
}

@media only screen and (max-width: 600px){
    .navAreaMail {
        display: none;
    }
}