.navAreaNav {
    /* background: black; */

    /* border: 1px solid red; */

    background: #0E0E0E;

    width: 6%;
    min-width: 50px;

    /* min-height: 100vh;
    min-height: -webkit-fill-available; */
    max-height: 100vh;
    /* max-height: -webkit-fill-available; */
    /* height: 100%; */

    /* position: relative; */
    /* border: red 1px solid; */

    /* Align to middle of sidebar */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navElements {

    /* border: red 1px solid; */

    display: flex;
    flex-direction: column;
    align-items: center;

    /* position: sticky;
    top: 75% */

    position: fixed;
    top: 60%;
}



.navElements::after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 140px;
    margin: 20px auto 0px auto;
    /* padding: ; */
    background-color: white;
}

.linkedin {
    color: white;
}

.email {
    color: white;
}

.telegram {
    color: white;
}

.github {
    color: white;
}

.linkIcon:hover {
    color: yellowgreen;
}



.linkSocial {
    color: white;
    /* color: aqua; */

    min-width: 10px;
    max-width: 40px;

    height: 40px;
    margin: 10px;
    transition: 0.3s;
}

.linkSocial:hover {
    transform: translateY(-7px);
    transition: 0.3s;

    /* color: yellowgreen; */
}

@media only screen and (max-width: 600px) {
    .email {
        display: initial;
    }

    .navAreaNav {

        /* testing */
        /* min-height: -webkit-fill-available; */
        min-height: 100vh;
    }

    .navElements {
        position: fixed;
        top: 50%;
    }
}