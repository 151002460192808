
.footer {
    background: #0E0E0E;

    scroll-snap-align: start;
}

.footerContent {

    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 50px;
      
    color: white;
}

@media only screen and (max-width: 600px){
    .footerContent {
        padding-bottom: 150px;
    }
}