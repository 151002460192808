
* {
    /* border: 1px red solid; */
}

.introBackground {

    background: #0E0E0E;

    height: 100vh;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: 1fr;

    scroll-snap-align: start;
}

.introContentSection {
    color: white;

    grid-area: 1 / 1 / 1 / 15;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;


    text-align: start;

    /* margin-left: 5%;
    margin-right: 5%; */

    margin-left: 2vw;
    margin-right: 2vw;

    position: relative;
}

#myName {

    margin-bottom: 0.5rem;
    /* margin-left: 0.8rem; */
}

.introName {
    font-size: clamp(2rem, 7vw, 7rem);
    margin: 0;
}
.introQuote {
    color: lightgrey;
    
    font-size: clamp(1.4rem, 3vw, 2.5rem);

    margin: 10px 0 0 0;

    /* margin-top: 40px; */
}
.introWriteup {
    font-size: clamp(1rem, 1.4vw, 1.3rem);
    
    /* margin-top: 1rem; */
    margin-bottom: 2.5rem;
    line-height: 1.5;
    
}
.introHighlight {
    color: yellowgreen;
}


.introSplineSection {

    /* could make this section blury in the background (not clear) */

    grid-area: 1 / 10 / 1 / 21;
    z-index: 0;

    height: inherit;

}

.introSpline {
    margin: 0;

    width: 100%;
    height: 100%;
    display: block;
}

@media only screen and (max-width: 600px){
    .introSplineSection {
        display: none;
    }
    .introContentSection {
        grid-area: 1 / 1 / 1 / 21;
    }
}