* {
  /* border: 1px red solid; */
}


.aboutBackground {

  /* navy blue */
  /* background: #080015; */

  /* grey */
  background: #0E0E0E;


  height: 100vh;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;

  scroll-snap-align: start;
}

.aboutSectionText {



  position: relative;



  color: white;

  /* text-align: start; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */

  grid-area: 1 / 1 / 1 / 1;
}

.aboutSectionMainText {
  /* background: red; */
  /* margin-left: 5%; */
  /* margin-right: 5%; */
  margin-left: 2vw;
  margin-right: 2vw;
}

.aboutSectionTitle {
  position: absolute;
  top: 6%;
  /* left: 6%; */


  text-align: left;

  margin-left: 6%;
  margin-right: 8%;
}

.aboutSectionText p {
  text-align: left;
  /* margin-left: 20px; */
  /* margin-right: 20px; */

  font-size: 1.2rem;
  line-height: 1.5;
}

@media only screen and (max-width: 600px) {
  .aboutSectionText p {
    font-size: 1rem;
  }
}


.aboutSectionImage {

  display: flex;

  justify-content: center;
  align-items: center;

  grid-area: 1 / 2 / 1 / 2;
}

.aboutSectionImage img {
  max-width: 70%;
  max-height: 70%;
}


/* 
ul {
    columns: 2;
} */

.tech-stack {
  list-style-type: none;
  display: grid;
  /* column-gap: 10px; */


  /* grid-template-columns: repeat(2, minmax(150px, 100px)); */
  grid-template-columns: repeat(2, 1fr);

  /* justify-content: center; */
  padding-left: 10px;
  padding-right: 10px;
  /* align-items: center; */
  /* justify-items: center; */
  /* align-content: center; */
}

.tech-stack li {



  /* position: relative; */
  /* padding-left: 20px; */
  text-align: left;
  /* width: 160px; */
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 14px;

  font-size: 1.1rem;
}

@media only screen and (max-width: 600px) {
  .tech-stack li {
    font-size: 1rem;
  }
}

.tech-stack li::before {

  content: "▹ ";
  /* content: "a    "; */
  color: yellowgreen;
  position: relative;
  /* right: 10px; */
  /* padding-left: 10px; */
}

@media only screen and (max-width: 600px) {
  .aboutSectionImage {
    display: none;
  }

  .aboutSectionText {
    grid-area: 1/1/1/3;
  }
}