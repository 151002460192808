* {
  /* border: 1px solid red; */
}

.App {
  /* border: 1px solid red; */

  text-align: center;
  display: inline-flex;
  /* width: 100%; */

  /* overflow: hidden; */
  /* overscroll-behavior: contain; */

  /* padding: 1px; */
}

.fontHelveticaNeue {
  font-family: "Helvetica Neue", "HelveticaNeue-Light", "Helvetica Neue Light", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.fontSpaceMono {
  font-family: 'Space Mono';
}


.sectionNumber {
  margin: 0;
  display: inline;

  font-family: 'Space Mono';

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #B5DC2A;
}

.sectionTitle {
  color: white;

  margin: 0;
  display: inline;
}

.Content {
  /* issues with this? */

  /* border: 1px solid red; */

  overflow-y: scroll;

  /* Type of snap */
  scroll-snap-type: y mandatory;
  /* height: 100vh; */

  /* possible chrome and safari fix */
  /* min-height: 100vh; */
  max-height: -webkit-fill-available;
  max-height: 100vh;

  width: 100%;
}

@media only screen and (max-width: 900px) {
  .Content {
    scroll-snap-type: none;

    /* testing */
    min-height: 100vh;
    /* height: -webkit-fill-available; */
  }
}

.Content::-webkit-scrollbar {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* space-mono-regular - latin */
@font-face {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../public/fonts/space-mono-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../public/fonts/space-mono-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}