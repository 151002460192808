* {
    /* border: 1px solid red; */
}

.otherProjectsBackground {

    background: #0E0E0E;

    /* old */
    /* min-height: 100vh;
    height: 100%;
    overflow: hidden; */
    
    /* new - mobile size adapted */
    min-height: 100vh;
    overflow: auto;
    display: block;




    scroll-snap-align: start;
}

.otherProjectsFocus {

    height: 100%;

    /* old */
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly; */
    
    /* new */
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    position: relative;

    padding-top: 16vh;
    /* padding-top: 20vh; */
}

.projectsOtherGrid {

    /* background: pink; */

    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    justify-content: center;

    /* gap: 40px; */

    /* width: 90%; */
    /* width: 80vw; */
    position: relative;


    margin-right: auto;
    margin-left: auto;
}

.otherProjectsFocusTitle{
    /* margin-left: 8%; */
    /* margin-right: 4%; */
    text-align: left;




    position: absolute;
    left: 7vh;
    top: 7vh;
    

    
}


/* new section: */

.project-container {

    width: 80vw;
    /* background: bisque; */
    /* margin-top: 20px; */
    /* margin-left: 5%; */
    /* margin-right: 5%; */
}

.projects-grid {
    /* box-sizing: border-box; */

    /* background: blue; */
    margin: auto;
    height: 100%;

    display: grid;
    /* grid-template-columns: repeat(3, minmax(300px, 1fr)); */
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 35px;
    padding: 0px;

    /* justify-content: center; */
    /* align-content: center; */
    /* justify-items: center; */
    /* align-items: center; */
}

@media (max-width: 1080px) {
    .projects-grid {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
}

.projects-card {

    text-align: start;

    background-color: grey;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    /* height: 90%; */
    height: 225px;

    /* padding for content within */
    padding: 1rem 1rem; 

    border-radius: 16px;

    transition: 0.3s;
}

.projects-card:hover {
    background-color: rgb(167, 167, 167);

    transform: translateY(-7px);
    transition: 0.3s;
}

.card-header {
    /* margin-top: -20px; */
    display: flex;
    /* padding: 1.25em 0 !important; */
    flex-direction: row;
    justify-content: space-between;
    border-bottom: none !important;
}

.folder-icon {
    /* color: yellowgreen; */
    color: white;
}

.github-icon {
    position: absolute;
    top:5%;
    right:5%;
    /* margin-top: 6px; */
    color: white;

    /* animation */
    opacity: 0;
    transition: .5s;
}

.github-icon:hover {
    color: yellowgreen;
}

.link-icon {
    position: absolute;
    top:5%;
    right:20%;
    /* margin-top: 6px; */
    color: white;

    /* animation */
    opacity: 0;
    transition: .5s;
}

.link-icon:hover {
    color: yellowgreen;
}



.projects-card:hover .github-icon {
    /* animation */
    opacity: 1;
    transition: .5s;
}
.projects-card:hover .link-icon {
    /* animation */
    opacity: 1;
    transition: .5s;
}

.card-title {
    font-family: "NTR", sans-serif;
    color: white;
    margin-bottom: 3%;

    font-size: 1.45rem;
    font-weight: bold;
}

.card-desc {
    /* margin: auto; */
    font-family: "NTR", sans-serif;
    color: white;
    
    font-size: 1.15rem;

    margin-bottom: 18px;
}

.card-tech {
    position: absolute;
    bottom: 5%;
    /* display: inline-block; */
    /* align-self: flex-end; */
    
    /* position: relative; */
    /* bottom: 5%; */
    /* padding-top: 5%; */
    /* margin-top: 5%; */
    font-family: "NTR", sans-serif;
    color: white;
    font-size: 16px;
}

@media only screen and (max-width: 600px) {
    .projects-card {
        height: 200px;
    }

    .otherProjectsBackground {
        height: auto;
    }
    .otherProjectsFocusTitle {
        /* padding: 20px; */
        margin-bottom: 30px;
        /* margin-top: 30px; */
        
    }
    .card-title {
        font-size: 1.3rem;
    }
    .card-desc {
        font-size: 0.95rem;
    }
}